const tabsAdapter = (data) => {
  return {
    text: data.text,
    title: data.title,
  };
};

export default ({ data }) => {
  return {
    tabs: data?.tabs.map(tabsAdapter),
  };
};
